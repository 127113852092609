import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Divider,
  Button,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";

import GalleryView from "../components/GalleryView";
import Signature from "../components/Signature";
import { db } from "../services/db";
import { fileToBlobUrl } from "../utils/Files";
import { useNavigate, useParams } from "react-router-dom";
import { osClose } from "../controllers/OSController";
import SignatureCanvas from "../components/SignatureCanvas";
import { useRecoilState } from "recoil";
import { loadingState, snackbar } from "../states";
import AlertDialog from "../components/AlertDialog";
import ModalItem from "../components/ModalItem";
import { ActionContainer, Container, IconContainer } from "../components/styles/Container";
import { Engineering } from "@mui/icons-material";
import Installation from "../components/CheckIn/Installation";
import Check from "../components/CheckIn/Check";
const Text = styled(TextField)`
  width: 100%;
  margin-top: 10px;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 0;
`;



export default function OS() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [, setSnack] = useRecoilState(snackbar);
  const [openModal, setShowModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [, setIsLoadingModal] = useRecoilState(loadingState);
  const [showSignature, setShowSignature] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [os, setOS] = useState({
    resolution: "",
    signature: null,
    manual: "",
    materials: [],
    media: [],
    step: 0
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [availableItems, setAvailableItems] = useState([]);

  function addMaterial(item, itemQnt, itemName) {
    const materials = os.materials ? os.materials : [];
    materials.push({ amount: itemQnt, name: itemName, id: item });
    const newOS = { ...os, materials: materials };
    setOS(newOS);
    saveOS(newOS);
    setOpen(false);
  }

  function removeMaterial(idx) {
    const newItems = [...os.materials];
    newItems.splice(idx, 1);
    const newOS = { ...os, materials: newItems };
    setOS(newOS);
    saveOS(newOS);
  }

  useEffect(() => {
    async function load() {
      const items = await db.products.toArray()
      setAvailableItems(items)
      const os = await db.os_open.get(parseInt(id));
      if (os.closed_at) {
        navigate(`/os/${os.id}/send`);
        console.log('closed os');
      }
      let len = os.media ? os.media.length : 0;
      let media = [];
      for (let i = 0; i < len; i++) {
        const file = os.media[i].file;
        const blobUrl = await fileToBlobUrl(file);
        media.push({ name: os.media[i].name, file, url: blobUrl });
      }
      setMediaData(media);
      const step = os.step > 2 ? 2 : (os.step ?? os.check_in ? 2 : 0);
      setOS((prev) => ({ ...prev, ...os, step }));
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function saveOS(newOS) {
    console.log(newOS ?? os);
    await db.os_open.put(newOS ?? os);
  }

  async function addMedia(newMedias) {
    const mediaOS = mediaData ?? [];
    const newOS = { ...os, media: [...(os.media ?? []), ...newMedias] };

    for (let i = 0; i < newMedias.length; i++) {
      let url = await fileToBlobUrl(newMedias[i].file);
      mediaOS.push({ name: newMedias[i].name, file: newMedias[i].file, url });
    }
    setMediaData(mediaOS);
    setOS(newOS);
    saveOS(newOS);
  }

  async function removeMedia(idx) {
    let newItems = [...mediaData];
    newItems = newItems.filter((x) => x.name !== idx);
    const newOS = { ...os, media: newItems };
    setOS(newOS);
    saveOS(newOS);
    setMediaData(newItems);
  }

  function checkContinue() {
    if (!os.client_name) {
      setSnack((prev) => ({
        ...prev,
        show: true,
        message: "Nome do cliente não preenchido",
        severity: "error",
      }));
      return false;
    }
    if (!os.client_cargo) {
      setSnack((prev) => ({
        ...prev,
        show: true,
        message: "Cargo do cliente não preenchido",
        severity: "error",
      }));
      return false;
    }
    if (!os.signature) {
      setSnack((prev) => ({
        ...prev,
        show: true,
        message: "Assinatura não preenchida",
        severity: "error",
      }));
      return false;
    }
    return true
  }

  async function next() {
    if (!os.resolution) {
      setSnack((prev) => ({
        ...prev,
        show: true,
        message: "Serviço realizado não preenchido",
        severity: "error",
      }));
      return false;
    }
    setOS(prev => ({ ...prev, step: prev.step + 1 }));
  }

  async function nextStep() {
    if (checkContinue()) {
      setShowModal(true);
    }
  }

  async function finishManutencao(boxImages) {
    setOS({ ...os, step: os.step + 1, check_out: boxImages, check_out_date: new Date().getTime() });
  }

  async function confirmFinish() {
    setShowModal(false);
    setIsLoadingModal({
      show: true,
      message: "Finalizando OS. Aguarde!",
    });
    await osClose(os);
    setIsLoadingModal((prev) => ({ ...prev, show: false }));
    navigate(`/os/${id}/send`);
  }

  async function finishCheckin(boxImages) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        const newOS = { ...os, step: os.step + 1, check_in: boxImages, check_in_date: new Date().getTime(), location: `${latitude},${longitude}` }
        setOS(newOS);
        saveOS(newOS);
      },
        err => {
          const newOS = { ...os, step: os.step + 1, check_in: boxImages, check_in_date: new Date().getTime(), location: `0,0` }
          setOS(newOS);
          saveOS(newOS);
        }
      )
    } else {
      const newOS = { ...os, step: os.step + 1, check_in: boxImages, check_in_date: new Date().getTime(), location: '0,0' }
      setOS(newOS);
      saveOS(newOS);
    }
  }

  function saveSignature(data) {
    const newOS = { ...os, signature: data };
    console.log(data);
    setOS(newOS);
    saveOS(newOS);
    setShowSignature(false);
  }

  function startWork() {
    setOS(prev => ({ ...prev, step: 1 }));
  }

  function onStepCheck(images) {

    console.log('change step', images)
    const newOS = { ...os, request: images }
    setOS(newOS);
    saveOS(newOS);
  }

  function finishRequest(data) {
    const newOS = { ...os, request: data, step: os.step + 1 };
    setOS(newOS);
    saveOS(newOS);
    console.log('finishRequest', data)
  }

  if (showSignature)
    return (
      <SignatureCanvas
        onSave={saveSignature}
        onCancel={() => setShowSignature(false)}
      />
    );

  if (os.step === 0) {
    return (
      <Container>
        <IconContainer>
          <Engineering fontSize="50px" />
        </IconContainer>
        <Typography>OS Nº: {os?.id} {os.installation ? (' - INSTALAÇÃO (' + os.box + ' Box)') : ' - MANUTENÇÃO'}</Typography>
        <Typography>CLIENTE: {os?.posto?.nome_fantasia} - ({os.posto?.numero_box} Box)</Typography>
        <Typography>DESCRIÇÃO: {os?.description}</Typography>
        {/* <Divider variant="fullWidth" /> */}
        <ActionContainer>
          <Button variant="contained" onClick={startWork}>
            INCIAR TRABALHO(CHECK-IN)
          </Button>
        </ActionContainer>
        {/* <AlertDialog
        // open={responseData.show}
        // title={responseData.title}
        // description={responseData.text}
        // onConfirm={continueOS}
        // ok={"OK"}
        /> */}
      </Container>
    )
    // <Typography>CLIENTE: {os?.posto?.nome_fantasia}</Typography>

  } if (os.step === 1) {
    return (
      <Installation checkIn os={os} finish={finishCheckin} />
    )
  } else if (os.step === 2) {
    return (
      <div>
        {/* <Typography>OS Nº: {os?.id} {os.installation ? (' - INSTALAÇÃO (' + os.box + ' Box)') : ''}</Typography>
        <Typography>CLIENTE: {os?.posto?.nome_fantasia}</Typography> */}
        {/* <Typography>DESCRIÇÃO: {os?.description}</Typography> */}
        {/* <Divider variant="fullWidth" /> */}
        <Typography mt={2}>Descreva o serviço efetuado</Typography>
        <Divider variant="fullWidth" />
        <Text
          id="outlined-basic"
          label="Serviço Realizado"
          variant="outlined"
          multiline
          value={os?.resolution}
          onChange={(e) =>
            setOS((prev) => ({ ...prev, resolution: e.target.value }))
          }
          onBlur={() => saveOS(os)}
          rows={2}
        />
        {/* <Typography marginTop={2}>
          Senha de desbloqueio: (caso alterada)
        </Typography> */}
        <Divider variant="fullWidth" />
        <Typography marginTop={2}>Fotos/Videos:</Typography>
        <Divider variant="fullWidth" />
        <GalleryView data={mediaData} setData={addMedia} onRemove={removeMedia} />
        <Typography marginTop={2}>Materiais:</Typography>
        <Divider variant="fullWidth" />
        {os.materials?.length > 0 && (
          <Paper>
            <List>
              {os.materials?.map((map, idx) => (
                <ListItem
                  disablePadding
                  key={idx}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => removeMaterial(idx)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton>
                    <ListItemText primary={`${map.amount} - ${map.name}`} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        <Button onClick={handleOpen}>Adicionar Material</Button>
        <ModalItem
          open={open}
          items={availableItems}
          onClose={handleClose}
          addMaterial={addMaterial}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description" />
        <Divider variant="fullWidth" />
        <Action>
          <Button variant="contained" onClick={next}>
            AVANÇAR
          </Button>
        </Action>
      </div>
    );
  }
  else if (os.step === 3) {
    return (
      <Check request={os.props?.request} continueRequest={os.request} finish={finishRequest} onStep={onStepCheck} />
    )
  }
  else if (os.step === 4) {
    return <Installation os={os} finish={finishManutencao} amount={os.materials.filter(x => x.name.toLocaleLowerCase().includes("kit box dianteiro"))[0]?.amount ?? 0} />
  }
  else {
    return <>
      <Typography marginTop={2}>Nome do Funcionário:</Typography>
      <Text
        id="outlined-basic"
        label="Nome Do Funcionário"
        variant="outlined"
        value={os?.client_name}
        onChange={(e) => setOS((prev) => ({ ...prev, client_name: e.target.value }))}
        onBlur={() => saveOS(os)}
      />
      <Typography marginTop={2}>Cargo do Funcionário:</Typography>
      <Text
        id="outlined-basic"
        label="Cargo"
        variant="outlined"
        value={os?.client_cargo}
        onChange={(e) => setOS((prev) => ({ ...prev, client_cargo: e.target.value }))}
        onBlur={() => saveOS(os)}
      />
      <Typography marginTop={2}>Assinatura do cliente:</Typography>
      <Signature onClick={() => setShowSignature(true)} data={os.signature} />
      <Action>
        <Button variant="contained" onClick={nextStep}>
          AVANÇAR
        </Button>
      </Action>
      <AlertDialog
        open={openModal}
        title="Deseja finalizar?"
        description="Ao finalizar não será possível mais editar, continuar?"
        onConfirm={confirmFinish}
        onCancel={() => setShowModal(false)}
      />
    </>
  }
}
